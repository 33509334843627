import React, { useCallback } from 'react';
import { TreeSelect } from 'antd';

const { SHOW_CHILD } = TreeSelect;

interface GroupedEnumFilterProps<T> {
  title: string;
  /**
   * Placeholders for the filter: [plural, singular]
   */
  placeholder: [string, string];
  groupMap: { [key: string]: { title: string; value: T }[] };
  value: T[];
  setValue: (value: T[]) => void;
  style?: React.CSSProperties | undefined;
  dropdownStyle?: React.CSSProperties | undefined;
  disabled: boolean;
}

function GroupedEnumFilter<T>({
  groupMap,
  value,
  setValue,
  disabled,
  title,
  placeholder,
  style = {},
  dropdownStyle = {}
}: GroupedEnumFilterProps<T>) {
  const treeData = [
    {
      key: 'ALL_THE_THINGS',
      value: 'ALL_THE_THINGS',
      title: <b>{`All ${placeholder[0]}`}</b>,
      children: Object.entries(groupMap).map(([group, options]) => ({
        key: group,
        value: group,
        title: <b>{group}</b>,
        children: options.map(option => ({
          ...option,
          key: option.value,
          value: option.value
        }))
      }))
    }
  ];

  const valuesCount = Object.values(groupMap).reduce((sum, options) => sum + options.length, 0);

  const mapSetValue = useCallback(
    (value: string[]) => {
      setValue((value as unknown) as T[]);
    },
    [setValue]
  );

  return (
    <TreeSelect
      className={value.length ? 'filter-selected' : ''}
      treeData={treeData}
      value={(value as unknown) as string[]}
      onChange={mapSetValue}
      showCheckedStrategy={SHOW_CHILD}
      placeholder={title}
      disabled={disabled}
      filterTreeNode={(input, node) =>
        node.props.children.length
          ? false
          : node.props.title.toUpperCase().includes(input.toUpperCase())
      }
      allowClear
      treeCheckable
      treeDefaultExpandAll
      dropdownMatchSelectWidth
      maxTagCount={0}
      maxTagPlaceholder={omittedValues =>
        omittedValues.length === valuesCount
          ? `All ${placeholder[0]}`
          : omittedValues.length === 1
          ? treeData[0].children.flatMap(g => g.children).find(o => o.value === omittedValues[0])
              ?.title ?? `1 ${placeholder[1]}`
          : `${omittedValues.length} ${placeholder[+(omittedValues.length === 1)]}`
      }
      style={{ width: '23rem', ...style }}
      dropdownStyle={{
        maxHeight: '250px',
        overflow: 'hidden',
        overflowY: 'scroll',
        ...dropdownStyle
      }}
    />
  );
}

export default GroupedEnumFilter;
