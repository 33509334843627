import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highmaps';
import MarkerClusters from 'highcharts/modules/marker-clusters';
import TiledWebMap from 'highcharts/modules/tiledwebmap';

import Dashboards from '@highcharts/dashboards';
import DataGrid from '@highcharts/dashboards/datagrid';
import LayoutModule from '@highcharts/dashboards/modules/layout';

import { getDashboardOptions } from './getDashboardOptions';
import './styles.scss';

import { EventLocation } from '../getData';

TiledWebMap(Highcharts);
LayoutModule(Dashboards);
MarkerClusters(Highcharts);

Dashboards.HighchartsPlugin.custom.connectHighcharts(Highcharts);
Dashboards.DataGridPlugin.custom.connectDataGrid(DataGrid);

Dashboards.PluginHandler.addPlugin(Dashboards.HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(Dashboards.DataGridPlugin);

export interface EventsExplorerDashboardProps {
  data: EventLocation[];
}

const Dashboard: React.FC<EventsExplorerDashboardProps> = props => {
  const boardRef = useRef<Dashboards.Board | null>(null);

  const options = getDashboardOptions(props.data);

  useEffect(() => {
    boardRef.current = Dashboards.board('events-explorer-dashboard', options);

    return () => {
      if (boardRef.current) {
        boardRef.current.destroy();
      }
    };
  }, [options]);

  return (
    <div
      data-testid="events-explorer-dashboard"
      id="events-explorer-dashboard"
      className="highcharts-light"
    />
  );
};

Dashboard.propTypes = {
  data: PropTypes.arrayOf<EventLocation>(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export const EventsExplorerDashboard = memo(Dashboard);
