import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styled from 'styled-components';

import {
  DropdownLabel,
  getAircraftRegistrationLabel,
  GroupSelectionOption,
  RotateIcon,
  Selected
} from '@spidertracks/components';
import MultiSelectOverlay from '@spidertracks/components/build/MultiSelectOverlay';

import { AircraftDetails } from '../../../types/aircraft';
import { getInstance } from '../../../common/api/spidertracks-sdk';

import { checkboxOptionTransformer } from '../../Settings/events/utils';

interface AircraftFilterProps {
  selectedAircraft: string[];
  setSelectedAircraft: (aircraft: string[]) => void;
  orgsToFilterBy: string[];
  disabled: boolean;
}

const ClosedText = styled.span`
  text-align: left;
`;

const byRegistration = (a: AircraftDetails, b: AircraftDetails) =>
  a.registration > b.registration ? 1 : -1;

const AircraftFilter = ({
  selectedAircraft,
  setSelectedAircraft,
  orgsToFilterBy,
  disabled
}: AircraftFilterProps) => {
  const [orgMap, setOrgMap] = useState<Record<string, string>>({});
  const [allAircraft, setAllAircraft] = useState<Record<string, AircraftDetails[]>>({});

  const fetchAircraftData = useCallback(async () => {
    const SpidertracksSDK = getInstance();
    const aircraftData = (await SpidertracksSDK.getAircraft()) as AircraftDetails[];

    aircraftData.sort(byRegistration);

    const orgsByName: Record<string, string> = {};
    const aircraftByOrg = aircraftData.reduce((prev, aircraft) => {
      const org = aircraft.org ?? { id: 'DELETED', orgName: 'DELETED' };
      if (!prev[org.id]) {
        prev[org.id] = [];
        orgsByName[org.id] = org.orgName;
      }

      prev[org.id].push(aircraft);
      return prev;
    }, {} as { [key: string]: AircraftDetails[] });

    setAllAircraft(aircraftByOrg);
    setOrgMap(orgsByName);
  }, [setAllAircraft, setOrgMap]);

  useEffect(() => {
    let isSubscribed = true;
    fetchAircraftData()
      .then(() => {
        if (!isSubscribed) {
          return;
        }
      })
      .catch(console.error);

    return () => {
      isSubscribed = false;
    };
  }, []);

  const aircraft = useMemo(() => {
    const orgIdList = orgsToFilterBy.length
      ? [...orgsToFilterBy] // Copy it so we can sort it - the param is readonly.
      : Object.keys(allAircraft);
    orgIdList.sort((a, b) => (orgMap[a] > orgMap[b] ? 1 : -1));

    return orgIdList.reduce((acc, id) => {
      const aircraftDetail = allAircraft[id];
      if (aircraftDetail) {
        acc.push(...aircraftDetail);
      }
      return acc;
    }, [] as AircraftDetails[]);
  }, [orgsToFilterBy, orgMap, allAircraft]);

  const [visible, setVisible] = useState(false);

  const confirm = () => setVisible(false);
  const reset = () => {
    setSelectedAircraft(['*']);
    setVisible(false);
  };

  const onSelect = (selectedItems: Selected[]): void => {
    const usedGroups = selectedItems.reduce((prev: string[], selected) => {
      if (selected.group !== '' && !prev.includes(selected.group)) {
        return [selected.group, ...prev];
      }
      return prev;
    }, []);

    const reducedItems = selectedItems.filter(e => e.group === '').map(e => e.value.toString());
    const value: string[] = [...reducedItems, ...usedGroups];

    setSelectedAircraft(value);
  };

  const closedLabel = getAircraftRegistrationLabel(selectedAircraft, 'Select aircraft');

  const options = useMemo(() => aircraft.map(checkboxOptionTransformer(false)), [aircraft]);
  const cons: [string, AircraftDetails][] = aircraft.map(value => {
    return [value.id, value];
  });
  const aircraftById = useMemo(
    () => new Map<string, AircraftDetails>([...cons]),
    [aircraft]
  );

  const groups: GroupSelectionOption[] = useMemo(
    () => [
      {
        label: 'All aircraft',
        id: '*',
        onSelectFilter: () => true,
        isSelectAll: true
      },
      {
        label: 'All fixed wing',
        id: 'FIXED_WING',
        onSelectFilter: (value: CheckboxValueType) =>
          aircraftById.get(value as string)?.type == 'FIXED_WING'
      },
      {
        label: 'All rotary wing',
        id: 'ROTARY_WING',
        onSelectFilter: (value: CheckboxValueType) =>
          aircraftById.get(value as string)?.type == 'ROTARY_WING'
      }
    ],
    [aircraftById]
  );

  return (
    <Dropdown
      disabled={disabled}
      onVisibleChange={() => setVisible(!visible)}
      overlay={
        <MultiSelectOverlay
          displayName="Aircraft"
          onConfirm={confirm}
          onReset={reset}
          onSelect={onSelect}
          options={options}
          selected={selectedAircraft}
          showSearch
          shifted={4}
          groups={groups}
          showFooter={false}
        />
      }
      trigger={['click']}
      visible={visible}
    >
      <DropdownLabel onClick={e => e.preventDefault()} disabled={disabled}>
        <ClosedText>{closedLabel}</ClosedText>
        {/*//QUESTION: why rotate if we are covering it up on open?*/}
        <RotateIcon type="down" rotate={+visible * 180} />
      </DropdownLabel>
    </Dropdown>
  );
};

export default AircraftFilter;
