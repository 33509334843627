import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TreeSelect } from 'antd';

const { SHOW_CHILD } = TreeSelect;

import { Organisation } from '../../../common/api/spidertracks-sdk/types/Organisation';
import { getInstance } from '../../../common/api/spidertracks-sdk';

interface OrgsFilterProps {
  selectedOrgs: string[];
  setSelectedOrgs: (value: string[]) => void;
  disabled: boolean;
  style?: React.CSSProperties | undefined;
  dropdownStyle?: React.CSSProperties | undefined;
}

const byOrgName = (a: Organisation, b: Organisation) => (a.org.orgName > b.org.orgName ? 1 : -1);

const OrgsFilter = ({
  selectedOrgs,
  setSelectedOrgs,
  disabled,
  style,
  dropdownStyle
}: OrgsFilterProps) => {
  const [memberOrgs, setMemberOrgData] = useState<Organisation[]>([]);

  const fetchOrgData = useCallback(async () => {
    const SpidertracksSDK = getInstance();
    const userData = await SpidertracksSDK.getUsers();
    const memberOrgs = [...userData.orgs];

    memberOrgs.sort(byOrgName);

    setMemberOrgData(memberOrgs);
    return memberOrgs;
  }, [setMemberOrgData]);

  useEffect(() => {
    fetchOrgData().catch(console.error);
  }, [fetchOrgData]);

  const options = useMemo(
    () => memberOrgs.map(({ org: { id, orgName } }) => ({ label: orgName, value: id })),
    [memberOrgs]
  );

  const ROOT_VALUE = 'ALL_THE_THINGS';

  const treeData = [
    {
      key: ROOT_VALUE,
      value: ROOT_VALUE,
      title: <b>All Organisations</b>,
      children: options.map(({ label, value }) => ({
        key: value,
        value,
        title: label,
        children: []
      }))
    }
  ];

  const valuesCount = memberOrgs.length;

  return (
    <TreeSelect
      className={selectedOrgs.length ? 'filter-selected' : ''}
      treeData={treeData}
      value={selectedOrgs}
      onChange={setSelectedOrgs}
      showCheckedStrategy={SHOW_CHILD}
      filterTreeNode={(input, node) =>
        node.props.value === ROOT_VALUE
          ? false
          : node.props.title.toUpperCase().includes(input.toUpperCase())
      }
      placeholder="Organisations"
      disabled={disabled}
      allowClear
      treeCheckable
      treeDefaultExpandAll
      dropdownMatchSelectWidth
      maxTagCount={0}
      maxTagPlaceholder={omittedValues =>
        omittedValues.length === valuesCount
          ? 'All Organisations'
          : omittedValues.length === 1
          ? options.find(o => o.value === omittedValues[0])?.label ?? '1 Organisation'
          : `${omittedValues.length} ${
              omittedValues.length === 1 ? 'Organisation' : 'Organisations'
            }`
      }
      style={{ width: '20rem', ...style }}
      dropdownStyle={{
        maxHeight: '250px',
        overflow: 'hidden',
        overflowY: 'scroll',
        ...dropdownStyle
      }}
    />
  );
};

export default OrgsFilter;
